.contacts {
    background-image: url('../../images/Contact.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 40px;
  }

  .text{
      text-align: center;
      margin-top: 40px;
  }