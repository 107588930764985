
.box {
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  margin-bottom: 100px;
}

.grid {
  min-height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  background-image: url('../images/OceanWaves.jpeg');
}

.card-button{ 
  margin-left: auto;
  margin-right: auto;
}
