  
  .portfolio {
    background-image: url('../../images/Paper.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 50px;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 1200px;
  }

  .portfolio-image-container{
    align-items: center;
    justify-content: center;
    display: flex;
    height: 100%;
    width: 100%;
  }
